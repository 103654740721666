import {Fragment, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
  cal_price_percent,
  class_decoration_line,
  class_price_percent,
  class_text_danger,
  count_weekdays,
  format_date,
  format_float,
  format_price,
  isNullOrUndefined,
  map_industry,
  map_vung,
} from '../common/util';
import LoadingSpinner from "../components/LoadingSpinner";
import FBOXService from "../services/FBOXService";
import {parse} from "date-fns";
import TDTicker from "../components/TDTicker";


function TableHead() {
  return (
    <>
      <thead>
      <tr>
        <th className="th-ticker">ticker</th>
        <th className="th-industry">industry</th>
        <th>date</th>
        <th className="th-t">T</th>
        <th>priceFlat</th>
        <th>pricePercent</th>
        <th>buyDate</th>
        <th>buyPrice</th>
        <th>sellDate</th>
        <th>sellPrice</th>
        <th className="th-profit">profit</th>
      </tr>
      </thead>
    </>
  )
}

function TableBody({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }

  const rowsArr = []
  for (const i in rows) {
    if (isNullOrUndefined(rows[i].tickerData)) {
      continue;
    }
    let item = {
      'title': rows[i].title,
      'increaseCount': rows[i].increaseCount,
      'decreaseCount': rows[i].decreaseCount,
      'normalCount': rows[i].normalCount,
      'averageIncrease': rows[i].averageIncrease,
      'averageDecrease': rows[i].averageDecrease,
      'averageIncome': rows[i].averageIncome,
      'vnindex': rows[i].vnindex,
      'tickerData': []
    }
    const tickers = Object.values(rows[i].tickerData);
    tickers.forEach((ticker) => {
      let tickerObj = JSON.parse(ticker)
      const startDate = parse(tickerObj.buyDate, 'yyyyMMdd', new Date())
      const endDate = parse(tickerObj.date, 'yyyyMMdd', new Date())
      const t = count_weekdays(startDate, endDate)
      tickerObj['t_label'] = t + ' | ' + map_vung(t)
      tickerObj['t'] = t
      item['tickerData'].push(tickerObj);
    });
    item['tickerData'].sort((a, b) => {
      return b.buyDate - a.buyDate
    })
    rowsArr.push(item)
  }

  return (
      <tbody>
      {
        rowsArr.map((row, i) => {
          return (
            <Fragment key={i}>
              <tr>
                <td colSpan={12}>
                  {row.title}
                  <ul>
                    <li>averageIncome: <span className={class_text_danger(row.averageIncome)}>{format_float(row.averageIncome * 100)} %</span></li>
                    <li><span className={class_text_danger(row.vnindex)}>vnindex: {format_float(row.vnindex * 100)} %</span></li>
                  </ul>
                </td>
              </tr>
              {
                Object.values(row.tickerData).map((ticker, j) => {
                  return (
                    <tr key={j} className={class_decoration_line(ticker.sellDate)}>
                      <TDTicker ticker={ticker}/>
                      <td className="text-start">{map_industry(ticker.industry)}</td>
                      <td className="text-end">{format_date(ticker.date)}</td>
                      <td className="text-start">{ticker.t_label}</td>
                      <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{format_price(ticker.priceFlat)}</td>
                      <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{cal_price_percent(ticker.pricePercent)}</td>
                      <td className="text-end">{format_date(ticker.buyDate)}</td>
                      <td className="text-end">{format_float(ticker.buyPrice, 2)}</td>
                      <td className="text-end">{format_date(ticker.sellDate)}</td>
                      <td className="text-end">{format_float(ticker.sellPrice, 2)}</td>
                      <td className={"text-end" + class_text_danger(ticker.profit)}>{format_float(ticker.profit * 100)} %</td>
                    </tr>
                  )
                })
              }
            </Fragment>
          )
        })
      }
      </tbody>
  );
}

function RecommendCS() {
  const title = 'CS';
  const [isLoading, setLoad] = useState(true);
  const [rowsTC, setRowsTC] = useState([]);
  const [rows50, setRows50] = useState([]);
  const [rowsSUPER, setRowsSUPER] = useState([]);

  useEffect(() => {
    FBOXService.fetch_recommend_cs('star').then(r => {
      setRowsSUPER(r.monthData)
      // setRowsSUPER(r.monthData.slice(0,2))
    })
    FBOXService.fetch_recommend_cs('top').then(r => {
      setRows50(r.monthData)
      // setRows50(r.monthData.slice(0,2))
    })
    FBOXService.fetch_recommend_cs('tc').then(r => {
      setRowsTC(r.monthData.slice(0,2))
    })
    setLoad(false)
  }, []);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
        <ul className="nav nav-pills" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="tc-tab" data-bs-toggle="tab" data-bs-target="#tc" type="button" role="tab" aria-controls="tc" aria-selected="false">TC</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="top50-tab" data-bs-toggle="tab" data-bs-target="#top50" type="button" role="tab" aria-controls="top50" aria-selected="false">50</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="super-tab" data-bs-toggle="tab" data-bs-target="#super" type="button" role="tab" aria-controls="super" aria-selected="true">SUPER</button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade" id="tc" role="tabpanel" aria-labelledby="tc-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead />
                <TableBody rows={rowsTC}/>
              </table>
            </div>
          </div>
          <div className="tab-pane fade" id="top50" role="tabpanel" aria-labelledby="top50-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead />
                <TableBody rows={rows50}/>
              </table>
            </div>
          </div>
          <div className="tab-pane fade show active" id="super" role="tabpanel" aria-labelledby="super-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead />
                <TableBody rows={rowsSUPER}/>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecommendCS;

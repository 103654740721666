import {
  Bar,
  BarChart,
  CartesianGrid, Cell, Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {useEffect, useState} from "react";
import CTTService from "../services/CTTService";

function History() {

  const [data, setData] = useState([]);

  useEffect(() => {
    CTTService.get_result_summary().then((response) => {
      setData(response);
    });
  }, []);

  return (
    <ResponsiveContainer minWidth="400" height={444}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="month"
          tickFormatter={(value) => value.substring(5, 7)}/>
        <YAxis
          tickFormatter={(value) => (value / 1000000)}/>
        <Tooltip
          formatter={(value) => `${value.toLocaleString()}`}/>
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="result">
          {
            data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.result > 0 ? '#82ca9d' : '#8884d8'} />
            ))
          }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default History;

import CTTService from './CTTService';


const LocalStorageService = {

  async fetch_all_tickers_one_time() {
    const tickers = await CTTService.get_all_tickers()
    window.localStorage.setItem('all_tickers', JSON.stringify(tickers))
  },

};

export default LocalStorageService;

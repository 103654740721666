import {format_float} from "../common/util";

function TDSignal(
  {
    signal,
    price,
    priceFlat=null
  }
) {
  let difference = '';
  if (price!= null && priceFlat != null) {
    difference += '(' + format_float((priceFlat - price) / priceFlat * 100) + '%)';
  }
  return (
    <td>
      {signal}
      <br/>
      <span className={"text-secondary"} style={{fontSize: "small"}}>{price} {difference}</span>
    </td>
  );
}

export default TDSignal;

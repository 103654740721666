import CTTService from './CTTService';


const SessionStorageService = {

  async fetch_follow_one_time() {
    const follow = await CTTService.get_my_catalog()
    window.sessionStorage.setItem('my_catalog', JSON.stringify(follow))
  },

  async fetch_margin_one_time() {
    const margin = await CTTService.get_margin_allowance()
    window.sessionStorage.setItem('margin_allowance', JSON.stringify(margin))
  },

  async fetch_vn30_one_time() {
    const r = await CTTService.get_tickers_by_group('VN30')
    window.sessionStorage.setItem('vn30', JSON.stringify(r))
  },

};

export default SessionStorageService;

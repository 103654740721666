import React from 'react';
import {createRoot} from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.esm.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import App from './app/App';
import axios from "axios";
import store from './app/store'
import {Provider} from 'react-redux'

const root = createRoot(document.getElementById('root'));

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (axios.isAxiosError(error)) {
      let error_detail = ''
      if (error.response) {
        error_detail = JSON.stringify(error.response.data.data);
      }
      if (error_detail === '') {
        if (window.confirm(error)) {
          window.location.reload();
        }
      } else {
        if (window.confirm(error_detail)) {
          window.location.reload();
        }
      }
    }
    return Promise.reject(error);
  }
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

import {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import LocalStorageService from '../services/LocalStorageService';
import ModalService from '../services/ModalService';
import SessionStorageService from '../services/SessionStorageService';
import logo from './logo.svg';
import ModalTickerDetail from './ModalTickerDetail';

import './navbar.css';
import {useDispatch} from "react-redux";
import {set_fetch_done} from "../features/fetchResultSlice";

function MainNavbar() {

  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch()

  useEffect(() => {
    console.log('MainNavbar useEffect')
    async function fetch_data() {
      await SessionStorageService.fetch_follow_one_time()
      await SessionStorageService.fetch_margin_one_time()
      await LocalStorageService.fetch_all_tickers_one_time()
      await SessionStorageService.fetch_vn30_one_time()
    }
    fetch_data().then(() => dispatch(set_fetch_done()))
  }, []);

  function handleSearchChange(e) {
    setSearchValue(e.target.value.toUpperCase())
  }

  function handleSearchClick(e) {
    if (searchValue === '') {
      return
    }
    return ModalService.open(ModalTickerDetail, {ticker: {ticker: searchValue}})
  }

  const items = [
    { path: '/', title: 'Home' },
    { path: '/summary', title: 'Summary' },
    { path: '/vn30', title: 'VN30' },
    { path: '/recommend/cs', title: 'CS' },
    { path: '/recommend/ps', title: 'PS' },
    { path: '/follow', title: 'Follow' },
    { path: '/margin', title: 'Margin' },
    { path: '/rule', title: 'Rule' },
    { path: '/chart', title: 'Chart' },
  ];

  function refreshPage() {
    window.location.reload(false);
  }

  function handleMenuClick() {
    document.getElementById('navbarMenu').classList.remove('show')
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand d-flex pt-2" href="/">
          <img src={logo} alt="" className="navbar-logo" />ReactJS
        </a>
        <button type="button" className="btn btn-outline-success btn-sm" onClick={refreshPage}>Refresh</button>
        <button className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarMenu"
                aria-controls="navbarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"/>
        </button>
        <div className="collapse navbar-collapse" id="navbarMenu">
          <ul className="navbar-nav me-auto">
            {
              items.map((item, i) => (
                <li key={i} className="nav-item">
                  <NavLink className="nav-link" to={item.path} onClick={ handleMenuClick }>{item.title}</NavLink>
                </li>
              ))
            }
          </ul>
          <div className="d-flex ms-auto">
            <input className="form-control" type="search" placeholder="Search" aria-label="Search" value={searchValue} onChange={ handleSearchChange }/>
            <span className="input-group-append">
                <button className="btn btn-outline-secondary bg-white border-start-0 border-bottom-0 border ms-n5" type="button" onClick={ handleSearchClick }>
                    <i className="bi bi-search"></i>
                </button>
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default MainNavbar;

import {createSlice} from '@reduxjs/toolkit'

export const fetchResultSlice = createSlice({
  name: 'fetch_result',
  initialState: {
    value: 'Fetching data...',
  },
  reducers: {
    set_fetch_done: (state) => {
      state.value = 'Fetch done!'
    },
  },
})

export const { set_fetch_done } = fetchResultSlice.actions
export default fetchResultSlice.reducer

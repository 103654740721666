import {class_text_danger, format_float} from "../common/util";

function TDGDNN({ticker}) {
  if (!ticker.giaodichnn) {
    return <td></td>
  }
  const total = ticker.priceFlat * 1000 * ticker.volumeFlat
  let percent = format_float(ticker.giaodichnn * 1e9 / total * 100, 0)
  percent = (percent < 0) ? percent * (-1) : percent
  return (
    <td className={"text-end" + class_text_danger(ticker.giaodichnn)}>
      {format_float(ticker.giaodichnn)}
      <br/>
      {percent}%
    </td>
  )
}

export default TDGDNN;

import {useDispatch, useSelector} from 'react-redux'

function Home() {
  const count = useSelector((state) => state.counter.value)
  const fetch_result = useSelector((state) => state.fetch_done.value)
  const dispatch = useDispatch()

  return (
    <div className="container-fluid text-center">
      <h4>{fetch_result}</h4>
    </div>
  );
}

export default Home;

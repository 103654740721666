import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {class_text_danger, format_date, format_float, isNullOrUndefined} from '../common/util';
import LoadingSpinner from '../components/LoadingSpinner';
import FBOXService from '../services/FBOXService';
import TDTrend from "../components/TDTrend";

function mapOrderTrendTrading(s) {
  if (s == 'Nothing') {
    return '';
  } else if (s == 'Short') {
    return 'S';
  } else {
    return 'L';
  }
}

function TableHead1() {
  return (
    <>
      <thead>
      <tr>
        <th className="th-rawDate">rawDate</th>
        <th>trend</th>
        <th>matchPrice</th>
        <th>income</th>
      </tr>
      </thead>
    </>
  )
}

function TableBody1({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  const rowsArr = [];
  for (const i in rows) {
    rowsArr.push(JSON.parse(rows[i]));
  }
  return (
    <>
      <tbody>
      {
        rowsArr.map((row, i) => (
          <tr key={i}>
            <td>{format_date(row.rawDate, 'yyyyMMddHHmmss', 'dd/MM - HH:mm')}</td>
            <td className="text-center">{mapOrderTrendTrading(row.orderTrendTrading)}</td>
            <td className="text-end">{format_float(row.matchPriceMixTrading)}</td>
            <td className={"text-end" + class_text_danger(row.incomeTrendTrading)}>{format_float(row.incomeTrendTrading)}</td>
          </tr>
        ))
      }
      </tbody>
    </>
  );
}

function TableHead2() {
  return (
    <>
      <thead>
      <tr>
        <th className="th-rawDate">rawDate</th>
        <th>close</th>
        <th>base</th>
        <th>trend</th>
        <th>turnLong</th>
        <th>turnShort</th>
        <th>orderTrendTrading</th>
        <th>matchPrice</th>
      </tr>
      </thead>
    </>
  )
}

function TableHead3() {
  return (
    <>
      <thead>
      <tr>
        <th>date</th>
        <th>total_orders</th>
        <th>profit</th>
      </tr>
      </thead>
    </>
  )
}

function TableHead4() {
  return (
    <>
      <thead>
      <tr>
        <th>month</th>
        <th>total_orders</th>
        <th>profit</th>
      </tr>
      </thead>
    </>
  )
}

function mapBase(s) {
  if (s === 'Nothing' || s === 'Sideway') {
    return '';
  } else {
    return s;
  }
}

function mapMatchPriceMixTrading(s) {
  if (s === 0) {
    return '';
  } else {
    return format_float(s);
  }
}

function mapTurnLS(s) {
  if (s === 0) {
    return '';
  } else {
    return s
  }
}

function TableBody2({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  const rowsArr = [];
  for (const i in rows) {
    rowsArr.push(JSON.parse(rows[i]));
  }
  return (
    <>
      <tbody>
      {
        rowsArr.map((ticker, i) => (
          <tr key={i}>
            <td>{format_date(ticker.rawDate, 'yyyyMMddHHmmss', 'dd/MM - HH:mm')}</td>
            <td className="text-center">{ticker.close}</td>
            <td className="text-center">{mapBase(ticker.base)}</td>
            <TDTrend ticker={ticker} />
            <td className="text-center">{mapTurnLS(ticker.turnLong)}</td>
            <td className="text-center">{mapTurnLS(ticker.turnShort)}</td>
            <td className="text-end">{mapOrderTrendTrading(ticker.orderTrendTrading)}</td>
            <td className="text-end">{mapMatchPriceMixTrading(ticker.matchPriceTrendTrading)}</td>
          </tr>
        ))
      }
      </tbody>
    </>
  );
}

function TableBody3({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  const rowsArr = [];
  for (const i in rows) {
    const items = rows[i].split(':')
    rowsArr.push({
      date: items[0],
      total_orders: items[1],
      profit: items[2]
    });
  }
  return (
    <>
      <tbody>
      {
        rowsArr.map((row, i) => (
          <tr key={i}>
            <td>{format_date(row.date, 'yyyyMMdd', 'yyyy-MM-dd')}</td>
            <td>{row.total_orders}</td>
            <td className={class_text_danger(row.profit)}>{format_float(row.profit)}</td>
          </tr>
        ))
      }
      </tbody>
    </>
  );
}

function TableBody4({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  const rowsArr = [];
  for (const i in rows) {
    const items = rows[i].split(':')
    rowsArr.push({
      month: items[0],
      total_orders: items[1],
      profit: items[2]
    });
  }
  return (
    <>
      <tbody>
      {
        rowsArr.map((row, i) => (
          <tr key={i}>
            <td>{format_date(row.month, 'yyyyMM', 'yyyy-MM')}</td>
            <td>{row.total_orders}</td>
            <td className={class_text_danger(row.profit)}>{format_float(row.profit)}</td>
          </tr>
        ))
      }
      </tbody>
    </>
  );
}

function RecommendPS() {
  const title = 'PS';
  const [isLoading, setLoad] = useState(true);
  const [currentPSCode, setCurrentPSCode] = useState('');
  const [orderIncomeRecords, setOrderIncomeRecords] = useState([]);
  const [allRecords, setAllRecords] = useState([]);
  const [dayRecords, setDayRecords] = useState([]);
  const [monthRecords, setMonthRecords] = useState([]);

  useEffect(() => {
    FBOXService.fetch_recommend_ps().then(r => {
      setOrderIncomeRecords(r.orderIncomeRecords);
      setAllRecords(r.allRecords);
      setDayRecords(r.dayRecords);
      setMonthRecords(r.monthRecords);

      const firstOrder = JSON.parse(r.allRecords[0])
      setCurrentPSCode(firstOrder.symbol);

      setLoad(false)
    })
  }, []);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
        <ul className="nav nav-pills" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="chart-tab" data-bs-toggle="tab" data-bs-target="#chart" type="button" role="tab" aria-controls="order" aria-selected="true">chart</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="order-tab" data-bs-toggle="tab" data-bs-target="#order" type="button" role="tab" aria-controls="order" aria-selected="true">order</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="all-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="all" aria-selected="true">all</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="day-tab" data-bs-toggle="tab" data-bs-target="#day" type="button" role="tab" aria-controls="day" aria-selected="true">day</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="month-tab" data-bs-toggle="tab" data-bs-target="#month" type="button" role="tab" aria-controls="month" aria-selected="true">month</button>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="chart" role="tabpanel" aria-labelledby="chart-tab">
            <iframe src={`https://chart.finbox.vn/mobile-chart?popup=true&symbol=${currentPSCode}&interval=1D&dark=true&userId=6532936b8cd6c21f16986da5`} width={"100%"} height={"700px"}/>
          </div>
          <div className="tab-pane fade" id="order" role="tabpanel" aria-labelledby="order-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead1 />
                <TableBody1 rows={orderIncomeRecords} />
              </table>
            </div>
          </div>
          <div className="tab-pane fade" id="all" role="tabpanel" aria-labelledby="all-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead2 />
                <TableBody2 rows={allRecords} />
              </table>
            </div>
          </div>
          <div className="tab-pane fade" id="day" role="tabpanel" aria-labelledby="day-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead3 />
                <TableBody3 rows={dayRecords} />
              </table>
            </div>
          </div>
          <div className="tab-pane fade" id="month" role="tabpanel" aria-labelledby="month-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead4 />
                <TableBody4 rows={monthRecords} />
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecommendPS;

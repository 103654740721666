import React from 'react';
import useAuth from '../hooks/useAuth';
import rule from '../components/rule.jpg';

function Rule() {
  const title = 'Rule';

  const { getSession } = useAuth();
  const user = getSession();

  return (
    <>
      <div className="container-fluid">
        <h3 align={"center"}>CHIẾN LƯỢC GIAO DỊCH PSCA</h3>
        <p className={"text-danger"}><strong>BƯỚC 1: CHIA TỶ TRỌNG</strong></p>
        <p><strong>Xác định tổng vốn giải ngân dự kiến</strong>
        </p>
        <p>Đầu tiên, bạn cần xác định sẽ giải ngân tối đa lượng vốn là bao nhiêu? Lượng vốn này bao gồm cả phần margin tối đa mà bạn sẽ sử dụng. Giả định số vốn gốc là 1 tỷ và margin là 800 triệu thì ta sẽ có:
        </p>
        <p>
          <em>Tổng vốn giải ngân dự kiến = 1,000,000,000 + 800,000,000 = 1,800,000,000 (VND)</em>
        </p>
        <p><strong>Xác định số mã đầu tư và vốn giải ngân dự kiến của từng mã</strong></p>
        <p>Một danh mục có số mã chia hết cho 3 sẽ dễ action hơn với Chiến lược giao dịch PSCA. Với đa số các nhà đầu tư, một danh mục có 6 mã là tối ưu nhất.
        </p>
        <p><em>Số mã = 6 mã</em></p>
        <p><em>Vốn giải ngân dự kiến của từng mã = 1,800,000,000 / 6 = 300,000,000 (VND)</em>
        </p>
        <p>Như vậy, vốn giải ngân dự kiến của từng mã sẽ là 300,000,000 (VND).</p>
        <p><strong>Chia tỷ trọng cổ phiếu theo xu hướng thị trường</strong></p>
        <ul>
          <li>Khi thị trường đang trong xu hướng&nbsp;<strong>[GIẢM]</strong>, cơ hội trên thị trường ít mà rủi ro thì lại rất nhiều. Bạn chỉ nên giữ tỷ trọng cổ phiếu ở mức thấp (0% - 33%) tương ứng với số mã là 2.
          </li>
          <li>Khi thị trường chuyển từ xu hướng&nbsp;<strong>[GIẢM]</strong><strong>&gt;&gt;&gt; [TRUNG LẬP]</strong>, bạn tăng tỷ trọng cổ phiếu lên mức trung bình (33% - 67%) tương ứng với số mã là 4.
          </li>
          <li>
            Và khi thị trường chuyển từ xu hướng&nbsp;<strong>[TRUNG LẬP]</strong><strong>&gt;&gt;&gt; [TĂNG]</strong>, bạn tăng tỷ trọng cổ phiếu lên mức cao (67% - 100%) tương ứng với số mã là 6.
          </li>
        </ul>
        <table className="table table-bordered table-sm caption-top">
          <tbody>
          <tr>
            <td></td>
            <td>
              <strong>GIẢM</strong>
            </td>
            <td>
              <strong>TRUNG LẬP</strong>
            </td>
            <td>
              <strong>TĂNG</strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Tỷ trọng tổng</strong>
            </td>
            <td>
              0% - 33%
            </td>
            <td>
              33% - 67%
            </td>
            <td>
              67% - 100%
            </td>
          </tr>
          <tr>
            <td>
              <strong>Số mã</strong>
            </td>
            <td>
              2
            </td>
            <td>
              4
            </td>
            <td>
              6
            </td>
          </tr>
          <tr>
            <td>
              <strong>Vốn giải ngân dự kiến</strong>
            </td>
            <td>
              600,000,000
            </td>
            <td>
              1,200,000,000
            </td>
            <td>
              1,800,000,000
            </td>
          </tr>
          </tbody>
        </table>
        <p className={"text-danger"}><strong>BƯỚC 2: CHỌN CỔ PHIẾU</strong></p>
        <p><strong>Sàng lọc Cổ phiếu phổ thông</strong></p>
        <p>Hãy chọn các cổ phiếu đạt điều kiện tối thiểu là Cổ phiếu phổ thông:</p>
        <ul>
          <li>
            <strong>Vốn hóa</strong>&nbsp;&gt;= 2,000 tỷ
          </li>
          <li><strong>Trung bình Giá trị giao dịch</strong>&nbsp;&gt;= 5 tỷ</li>
        </ul>
        <p><strong>Tìm mã mạnh nhất trong dòng mạnh nhất</strong>
        </p>
        <p>Hãy tìm các mã mạnh nhất trong dòng mạnh nhất có các đặc điểm gần nhất với các đặc điểm sau:
        </p>
        <ul>
          <li>
            Thuộc Top 15 dòng mạnh nhất thị trường theo chỉ báo&nbsp;<strong>Sức mạnh ngành</strong>&nbsp;của&nbsp;Finbox
          </li>
          <li>Thuộc Top 3 mã mạnh nhất trong dòng</li>
          <li>FS Rating &gt;= 80</li>
          <li>
            Chống chịu tốt hơn với những đợt giảm chung của thị trường</li>
          <li>Tạo đáy sớm và bứt phá đầu tiên trong dòng</li>
          <li>Vận động mượt nhất trong dòng</li>
        </ul>
        <p><strong>Bắt tín hiệu</strong></p>
        <p>Với các cổ phiếu đã thỏa mãn 2 điều kiện trên, hãy chờ khi cổ phiếu có tín hiệu mua để hành động. Nếu có 2 cổ phiếu trở lên có tín hiệu cùng một lúc, hãy chọn&nbsp;<strong>cổ phiếu khỏe hơn</strong>.</p>
        <p>
          Cách đơn giản hơn là bạn có thể mua theo&nbsp;<strong>Danh mục khuyến nghị STOCK PICK</strong>&nbsp;của&nbsp;Finbox. Đây là danh mục đã được đội ngũ Admin&nbsp;Finbox&nbsp;kiểm định cả về cơ bản, kỹ thuật và thông tin.
        </p>
        <p className={"text-danger"}><strong>BƯỚC 3: MUA CỔ PHIẾU</strong></p>
        <p><strong>Chu kỳ giao dịch của mỗi cổ phiếu</strong>
        </p>
        <p>Mỗi cổ phiếu khi được mua trong danh mục sẽ có&nbsp;<strong>chu kỳ giao dịch 23 phiên (T+23)</strong>. Đây là chu kỳ giao dịch tối ưu nhất theo Chiến lược giao dịch PSCA. Sau 23 phiên, nếu cổ phiếu đã mua chưa được bán hết sẽ được tự động bán toàn bộ.
        </p>
        <ul>
          <li>
            <strong>Điểm mua gốc: </strong>Điểm mua đầu tiên T+0
          </li>
          <li>
            <strong>Giá mua gốc: </strong>Giá mua đầu tiên T+0
          </li>
          <li>
            <strong>Vùng tham gia: </strong>Vùng T+0 đến T+15
          </li>
          <li>
            <strong>Vùng cơ cấu: </strong>Vùng T+16 đến T+23
          </li>
          <li>
            <strong>Vùng bảo vệ: </strong>Vùng T+0 đến T+5
          </li>
        </ul>
        <p><strong>Điểm mua chuẩn</strong></p>
        <table className="table table-bordered table-sm caption-top">
          <tbody>
          <tr>
            <td>
              <strong>TÍN HIỆU</strong>
            </td>
            <td>
              <strong>VÙNG</strong>
            </td>
            <td>
              <strong>HÀNH ĐỘNG</strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>SMALL BUY</strong>
            </td>
            <td>
              T+0 đến T+15
            </td>
            <td>
              <ul>
                <li>Mua 1/3</li>
                <li>Lãi 2% gia tăng 1/3</li>
                <li>Lãi 4% gia tăng 1/3</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <strong>BIG BUY</strong>
            </td>
            <td>
              T+0 đến T+15
            </td>
            <td>
              <ul>
                <li>Mua 2/3</li>
                <li>Lãi 2% gia tăng 1/3</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
        <p><strong><em>Lưu ý:</em></strong><em>&nbsp;Điểm mua chuẩn chỉ được thực hiện tại&nbsp;</em><strong><em>Vùng tham gia</em></strong></p>
        <p><strong>Điểm mua pullback</strong></p>
        <table className="table table-bordered table-sm caption-top">
          <tbody>
          <tr>
            <td>
              <strong>TÍN HIỆU</strong>
            </td>
            <td>
              <strong>VÙNG</strong>
            </td>
            <td>
              <strong>HÀNH ĐỘNG</strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>PULLBACK MA10</strong>
            </td>
            <td>
              T+0 đến T+15
            </td>
            <td>
              <ul>
                <li>Mua 1/3</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <strong>PULLBACK MA20</strong>
            </td>
            <td>
              T+0 đến T+15
            </td>
            <td>
              <ul>
                <li>Mua 1/3</li>
                <li>Lãi 2% gia tăng 1/3</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
        <p><strong><em>Lưu ý:</em></strong><em>&nbsp;Điểm mua pullback chỉ được thực hiện tại&nbsp;</em><strong><em>Vùng tham gia</em></strong></p>
        <p className={"text-danger"}><strong>BƯỚC 4: BÁN CỔ PHIẾU</strong></p>
        <p><strong>Điểm bán chốt lời - cắt lỗ</strong></p>
        <table className="table table-bordered table-sm caption-top">
          <tbody>
          <tr>
            <td>
              <strong>TÍN HIỆU</strong>
            </td>
            <td>
              <strong>VÙNG</strong>
            </td>
            <td>
              <strong>HÀNH ĐỘNG</strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>CHỐT LỜI</strong>
            </td>
            <td>
              T+0 đến T+23
            </td>
            <td>
              <ul>
                <li>Bán 1/3 nếu lãi +20%</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <strong>CẮT LỖ</strong>
            </td>
            <td>
              T+0 đến T+23
            </td>
            <td>
              <ul>
                <li>Bán toàn bộ nếu lỗ -7%</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <strong>T+5</strong>
            </td>
            <td>
              <p>T+5</p>
            </td>
            <td>
              <ul>
                <li>Bán 1/3 nếu lỗ -4%</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <strong>T+10</strong>
            </td>
            <td>
              T+10
            </td>
            <td>
              <ul>
                <li>Bán 1/3 nếu lỗ -2%</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <strong>T+15</strong>
            </td>
            <td>
              T+15
            </td>
            <td>
              <ul>
                <li>Bán 1/3 nếu lỗ -0%</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
        <p><strong><em>Lưu ý:</em></strong><em>&nbsp;Điểm bán chốt lời, cắt lỗ được thực hiện tại&nbsp;</em><strong><em>Tất cả các thời điểm</em></strong><em>. Điểm bán T+5, T+10, T+15 được thực hiện tại các mốc T+5, T+10, T+15.</em>
        </p>
        <p><strong>Điểm bán chuẩn</strong></p>
        <table className="table table-bordered table-sm caption-top">
          <tbody>
          <tr>
            <td>
              <strong>TÍN HIỆU</strong>
            </td>
            <td>
              <strong>VÙNG</strong>
            </td>
            <td>
              <strong>HÀNH ĐỘNG</strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>SMALL SELL</strong>
            </td>
            <td>
              T+6 đến T+23
            </td>
            <td>
              <ul>
                <li>Bán 1/3</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p><strong>BIG&nbsp;SELL</strong></p>
            </td>
            <td>
              T+6 đến T+23
            </td>
            <td>
              <ul>
                <li>Bán 2/3</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
        <p><strong><em>Lưu ý:</em></strong><em>&nbsp;Điểm bán chuẩn chỉ được thực hiện ngoài&nbsp;</em><strong><em>Vùng bảo vệ</em></strong></p>
        <p><strong>Điểm bán cơ cấu</strong></p>
        <table className="table table-bordered table-sm caption-top">
          <tbody>
          <tr>
            <td>
              <strong>TÍN HIỆU</strong>
            </td>
            <td>
              <strong>VÙNG</strong>
            </td>
            <td>
              <strong>HÀNH ĐỘNG</strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>GÃY MA20</strong>
            </td>
            <td>
              T+16 đến T+23
            </td>
            <td>
              <ul>
                <li>Bán toàn bộ nếu gãy MA20</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <strong>FS RATING &lt; 60</strong>
            </td>
            <td>
              T+16 đến T+23
            </td>
            <td>
              <ul>
                <li>Bán toàn bộ nếu FS RATING &lt; 60</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
        <p><strong><em>Lưu ý:</em></strong><em>&nbsp;Điểm bán cơ cấu chỉ được thực hiện tại&nbsp;</em><strong><em>Vùng cơ cấu</em></strong></p>
        <p className={"text-danger"}><strong>BẢNG TÓM TẮT QUY TẮC MUA - BÁN THEO TÍN HIỆU</strong></p>
        <img src={rule}/>
      </div>
    </>
  );
}

export default React.memo(Rule);

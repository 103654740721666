import axios from 'axios';


const FBOXService = {
  async fetch_recommend_ps() {
    let response = await axios.post('https://derivative.finbox.vn/api/app/derivative/trend')
    return response.data.data
  },

  async fetch_recommend_cs(category) {
    let response = await axios.post('https://derivative.finbox.vn/api/app_new/tradingbox/all', { category: category })
    return response.data
  },

  async fetch_industry_rank() {
    let response = await axios.get('https://api2.finbox.vn/api/chart/industryRanking')
    return response.data
  },

};

export default FBOXService;

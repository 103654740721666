function TDTrend(
  {
    ticker
  }
) {
  let icon = '';

  if (ticker.trend.toLowerCase() === 'tăng') {
    icon = <i className="bi bi-arrow-up-right text-primary"></i>
  } else if (ticker.trend.toLowerCase() === 'giảm') {
    icon = <i className="bi bi-arrow-down-right text-danger"></i>
  }
  return (
    <td className="text-center">{ icon }</td>
  );
}

export default TDTrend;

import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counterSlice'
import fetchDoneReducer from '../features/fetchResultSlice'

export default configureStore({
  reducer: {
    counter: counterReducer,
    fetch_done: fetchDoneReducer,
  },
})

// eslint-disable-next-line max-len
export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const namePattern = /^[A-Za-z-]*$/;
export const HOLIDAYS = [
  new Date('2024-01-01T00:00:00+07:00'),
  new Date('2024-02-09T00:00:00+07:00'),
  new Date('2024-02-10T00:00:00+07:00'),
  new Date('2024-02-11T00:00:00+07:00'),
  new Date('2024-02-12T00:00:00+07:00'),
  new Date('2024-02-13T00:00:00+07:00'),
  new Date('2024-02-14T00:00:00+07:00'),
  new Date('2024-02-15T00:00:00+07:00'),
  new Date('2024-04-18T00:00:00+07:00'),
  new Date('2024-04-30T00:00:00+07:00'),
  new Date('2024-05-01T00:00:00+07:00'),
  new Date('2024-09-02T00:00:00+07:00'),
  new Date('2024-09-03T00:00:00+07:00'),
]

export const HOLIDAYS_SET = new Set(HOLIDAYS.map(holiday => holiday.getTime()));

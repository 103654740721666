import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
  class_price_percent,
  class_text_danger,
  format_date,
  format_float,
  format_price,
  getIncreaseRoom,
  map_industry,
} from '../common/util';
import LoadingSpinner from '../components/LoadingSpinner';
import CTTService from '../services/CTTService';
import TDTicker from "../components/TDTicker";
import TDTrend from "../components/TDTrend";


function TableHead() {
  return (
    <thead>
    <tr>
      <th>#</th>
      <th className="th-ticker">ticker</th>
      <th className="th-industry">industry</th>
      <th>date</th>
      <th>value</th>
      <th>priceFlat</th>
      <th>pricePercent</th>
      <th>trend</th>
      <th className="th-base">base</th>
      <th className="th-signal">signal</th>
      <th>priceAction</th>
      <th>fs_rating</th>
      <th>gdnn</th>
    </tr>
    </thead>
  )
}
function TableBody({ rows }) {
  return (
    <tbody>
    {
      rows && rows.map((ticker, i) => {
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <TDTicker ticker={ticker}/>
            <td className="text-start">{map_industry(ticker.industry)}</td>
            <td className="text-end">{format_date(ticker.date)}</td>
            <td className="text-end">
              {ticker.value.value_equity.min}-{ticker.value.value_equity.max}&nbsp;({getIncreaseRoom(ticker.value.value_equity.room)})
            </td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{format_price(ticker.priceFlat)}</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{format_float(ticker.pricePercent * 100)} %</td>
            <TDTrend ticker={ticker} />
            <td className="text-center">{ ticker.base }</td>
            <td className="text-center">{ ticker.signal }</td>
            <td className="text-end">{ format_price(ticker.gia_thuc_hien) }</td>
            <td className={"text-end" + class_text_danger(ticker.fs_rating, 60)}>{ ticker.fs_rating }</td>
            <td className={"text-end" + class_text_danger(ticker.giaodichnn)}>{format_float(ticker.giaodichnn)}</td>
          </tr>
        )
      })
    }
    </tbody>
  )
}

function Margin() {
  const title = 'Margin';
  const [isLoading, setLoad] = useState(true);
  const [marginList, setMarginList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [rows, setRows] = useState([]);
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    CTTService.get_margin_allowance_full().then(r => {
      r.sort((a, b) => {
        return a.industry.localeCompare(b.industry) || a.ticker.localeCompare(b.ticker)
      })
      setMarginList(r)
      setRows(r)

      let filters = r.filter(ticker => ticker.value.value_equity.room > 0)
        .sort((a, b) => b.value.value_equity.room - a.value.value_equity.room)
      setFilterList(filters)

      let industries = r.map(ticker => ticker.industry)
      industries = [...new Set(industries)].sort()
      industries.unshift('All')
      setIndustries(industries)

      setLoad(false)
    })
  }, []);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  function changeOption(e) {
    console.log(e.target.value)
    if (e.target.value === '0') {
      setRows(marginList)
      return
    }
    setRows(marginList.filter(ticker => ticker.industry === industries[e.target.value]))
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
        <ul className="nav nav-pills" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="filter-tab" data-bs-toggle="tab" data-bs-target="#filter" type="button" role="tab" aria-controls="filter" aria-selected="false">filter</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="all-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="all" aria-selected="false">all</button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="filter" role="tabpanel" aria-labelledby="filter-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead />
                <TableBody rows={filterList} />
              </table>
            </div>
          </div>
          <div className="tab-pane fade" id="all" role="tabpanel" aria-labelledby="all-tab">
            <select className="form-select form-select-sm mt-3" style={{width:'auto'}} aria-label=".form-select-sm" onChange={(e) => changeOption(e)}>
              {
                industries.map((item, i) => (
                  <option key={i} value={i}>{item}</option>
                ))
              }
            </select>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead />
                <TableBody rows={rows} />
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Margin;

import {class_text_danger, format_float, get_floor, has_margin, is_own, is_v30, map_industry} from "../common/util";
import ModalService from "../services/ModalService";
import ModalTickerDetail from "./ModalTickerDetail";

function TDTickerVol(
  {
    ticker,
    popup = true,
    highlight_own = true
  }
) {
  const ticker_value = ticker.ticker;

  const cls_profit = ticker.profit !== undefined ? class_text_danger(ticker.profit) : '';
  const cls_own = highlight_own && is_own(ticker_value) ? 'bg-warning' : '';
  const v30_text = is_v30(ticker_value) ? '*' : '';

  const more_classes = [cls_profit].join(' ');

  let margin_html = <span className={"no-margin"}></span>;
  if (has_margin(ticker_value)) {
    margin_html = <span className={"has-margin"}></span>
  }

  const handleClick = () => {
    if (popup) {
      ModalService.open(ModalTickerDetail, {ticker});
    }
  };

  return (
    <td className={more_classes}>
      {margin_html}
      <span className={cls_own} onClick={handleClick}>{ticker_value}{v30_text}</span>
      <div className={"text-secondary float-end"}>
        {map_industry(ticker.industry)}
        <span className="badge bg-secondary" style={{width:"2rem",marginLeft:5}}>{ticker.fs_rating}</span>
      </div>
      <br/>
      <span className={"text-secondary"} style={{fontSize: "small"}}>
        {ticker.volumeFlat.toLocaleString()} ({format_float(ticker.volumePercent * 100, 0)}%)
        <span className={"float-end"}>{get_floor(ticker_value)}</span>
      </span>
    </td>
  );
}

export default TDTickerVol;

import {class_price_percent, format_float} from "../common/util";

function TDPrice(
  {
    ticker
  }
) {
  return (
    <td className={class_price_percent(ticker.pricePercent)}>
      {ticker.priceFlat}
      <br/>
      <span style={{fontSize: "small"}}>{format_float(ticker.pricePercent * 100, 1)}%</span>
    </td>
  );
}

export default TDPrice;

import {useEffect, useState} from 'react';
import LoadingSpinner from './LoadingSpinner';
import CTTService from "../services/CTTService";
import {format_date, percentDiff} from "../common/util";
import {format} from "date-fns";


function replaceTime(dateString, newTime) {
  let date = new Date(dateString);
  let [hours, minutes, seconds] = newTime.split(':');
  date.setHours(hours, minutes, seconds);
  return format(date, 'dd/MM-HH:mm');
}

function TickerHistory({ticker}) {
  const [isLoading, setLoad] = useState(true);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    CTTService.get_ticker_history(ticker).then(res => {
      setRows(res)
      setTotal(res.reduce((acc, obj) => acc + obj.profit, 0))
      setLoad(false)
    })
  }, []);

  function format_percent_diff(percent) {
    if (percent === 0) {
      return <span className={"text-warning"}> (0%)</span>
    } else if (percent < 0) {
      return <span className={"text-danger"}> ({percent}%)</span>
    } else {
      return <span className={"text-success"}> ({percent}%)</span>
    }
  }

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover table-bordered table-sm caption-top">
        <caption>{total.toLocaleString()}</caption>
        <thead>
        <tr>
          <th style={{minWidth: "95px"}}>time</th>
          <th>orderQtty</th>
          <th style={{minWidth: "150px"}}>matchPrice</th>
          <th>profit</th>
        </tr>
        </thead>
        <tbody>
        {
          rows && rows.map((ticker, i) => {
            return (
              <tr key={i}>
                <td>{replaceTime(ticker.txdate, ticker.txtime)}</td>
                <td className="text-end">{ticker.orderQtty.toLocaleString()}</td>
                <td className="text-end">{ticker.matchPrice.toLocaleString()}/{ticker.costPrice.toLocaleString()} {format_percent_diff(percentDiff(ticker.matchPrice, ticker.costPrice))}</td>
                <td className="text-end">{ticker.profit.toLocaleString()}</td>
              </tr>
            )
          })
        }
        </tbody>
      </table>
    </div>
  );
}

export default TickerHistory;
